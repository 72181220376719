<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <h2 class="card-title">Kalendereinträge</h2>
                        <div class="clearfix card-tools">
                            <div class="row align-items-center" >                          
                                <div class="text-right col-md-12">

                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">  
                                            <li class="mr-1">
                                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                    <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="params.show_expired">
                                                    <label class="custom-control-label" for="customSwitch3">Zeige vergangene Einträge</label>
                                                </div>
                                            </li>
                                            <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm" style="margin-top: 0;">
                                                    <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getEntries" v-model="search" />
                                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="getEntries">
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                    <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="nav-item d-none d-md-block">
                                                <button type="button" class="mr-1 btn btn-sm btn-success" @click="getEntries" ><i class="fas fa-sync"></i></button>       
                                            </li>
                                            <li class="nav-item d-none d-md-block">
                                                <button type="button" class="btn btn-primary btn-sm mr-1" @click="createModal" v-if="$auth.check('calendar.create')"><i class="fas fa-plus-circle"></i> Neuer Termin</button>             
                                            </li>
                                        </ul>
                    
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-sm" v-if="dates.data.length >= 1">
                                <thead>
                                    <tr>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('title')">Titel</a>
                                            <span v-if="this.params.sort_field == 'title' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'title' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('start')">Start</a>
                                            <span v-if="this.params.sort_field == 'start' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'start' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('stop')">Stop</a>
                                            <span v-if="this.params.sort_field == 'stop' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'stop' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>Ganztägig</th>
                                        <th>Aktion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="date in dates.data" :key="date.id">
                                        <td>{{date.title}}</td>
                                        <td>{{date.start | formatDateTime}}</td>
                                        <td>{{ date.stop | formatDateTime }}</td>
                                        <td>
                                            <i v-if="date.allDay == 1" class="fas fa-check"></i>
                                            <i v-else class="fas fa-times"></i>
                                        </td>
                                        <td>
                                            <button v-if="$auth.check('calendar.edit')" type="button" class="mr-1 btn btn-xs btn-warning" @click="editModal(date)" ><i class="fas fa-fw fa-edit"></i></button>
                                            <button v-if="$auth.check('calendar.destroy')" type="button" class="mr-1 btn btn-xs btn-danger" @click="deleteEntry(date.id)"><i class="fas fa-fw fa-trash"></i></button>          
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span v-else><h5>Derzeit sind keine Kalendereinträge im System gespeichert.</h5></span>
                        </div>
                    </div>
                    <div class="card-footer">
                        <pagination class="float-left" :data="dates" @pagination-change-page="getEntries" :limit="3"></pagination>
                        
                        <select class="float-right form-control-sm" v-model="params.per_page">
                            <option value="25">25 Einträge</option>
                            <option value="50">50 Einträge</option>
                            <option value="75">75 Einträge</option>
                            <option value="100">100 Einträge</option>
                            <option value="125">125 Einträge</option>
                            <option value="150">150 Einträge</option>
                        </select>
                        <!-- <p v-if="notEmptyObject(dates)" class="float-right mr-2">Anzeige Eintrag {{ dates.meta.from }} - {{ dates.meta.to }} von {{ dates.meta.total }}</p> -->
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="entryModal" title="Neuigkeit" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="title" class="control-label">Titel*</label>
                            <input v-model="form.title" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('title')}">
                            <has-error :form="form" field="title"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="company">Ganztägig:</label>
                            <select class="form-control form-control-sm" v-model="form.allDay">
                                <option value="1">Ja</option>
                                <option value="0">Nein</option>
                            </select>
                            <has-error :form="form" field="allDay"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="start" class="control-label">Start</label>
                            <input v-model="form.start" type="datetime-local" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('start')}">
                            <has-error :form="form" field="start"></has-error>
                        </div>

                        <div class="form-group" v-if="form.allDay == 0">
                            <label for="stop" class="control-label">Stop</label>
                            <input v-model="form.stop" type="datetime-local" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('stop')}">
                            <has-error :form="form" field="stop"></has-error>
                        </div>


                        <div class="form-group">
                            <label for="company">Farbe:</label>
                            <select class="form-control form-control-sm" v-model="form.color">
                                <option value="#007bff">Blau</option>
                                <option value="#28a745">Grün</option>
                                <option value="#ffc107">Gelb</option>
                                <option value="#dc3545">Rot</option>
                                <option value="#757575">Grau</option>
                                <option value="#5700a3">Lila</option>
                                <option value="#00a5ab">Türkis</option>
                                <option value="#6b1d0d">Braun</option>
                            </select>
                            <has-error :form="form" field="color"></has-error>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal> 
    </b-container>
</template>

<script>
export default {
    data(){
        return {
            dates: {
                data: [],
            },
            editMode: false,
            modalTitle: "",
            form: new window.Form({
                id: '',
                title: '',
                start: '',
                stop: '',
                allDay: false,
                color: '',
            }),
            params: {
                sort_field: 'title',
                sort_direction: 'asc',
                per_page: 25,
                show_expired: false
            },
            page: 1,
            search: '',
        }
    },

    watch: {
        'params': {
            handler () {
                this.getEntries();
            },
            deep: true
        },
    },

    methods:{
        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        resetSearch(){
            this.search = '';
            this.getEntries();
        },
        handleOk(bvModalEvt, entry) {
            bvModalEvt.preventDefault()
            this.handleSubmit(entry)
        },

        handleSubmit(entry) {
            
            if(this.editMode == true)
            {
                //Edit User
                this.editEntry(entry); 
            }
            else
            {
                //Create User
                this.createEntry();
            }  
        },
        editModal(entry) {
            this.editMode = true;
            this.modalTitle = "Termin editieren";
            this.form.clear();
            this.form.reset();
            this.form.fill(entry);
            this.$bvModal.show("entryModal");

        },

        createModal() {
            this.editMode = false;
            this.modalTitle = "Neuen Termin anlegen";
            this.form.clear();
            this.form.reset();
            this.form.allDay = 0;
            this.$bvModal.show("entryModal");
        },

        createEntry(){
            this.form
                .post("/calendars")
                .then(() => {
                    this.getEntries();
                    this.$bvModal.hide("entryModal");
                    this.$swal({
                        icon: "success",
                        title: "Termin wurde hinzugefügt",
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        editEntry(id){
            this.form
                .put("/calendars/" + id)
                .then(() => {
                    this.getEntries();
                    this.$bvModal.hide("entryModal");
                    this.$swal({
                        icon: "success",
                        title: "Änderungen wurden gespeichert",
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        deleteEntry(id){
            this.$swal({
                title: "Möchtest du den Termin wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/calendars/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Termin erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.getEntries();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        getEntries(page)
        {
        if(page == undefined)
        {
            page = this.page 
        }
        this.axios
            .get("/calendars", {
                params:{
                    search: this.search,
                    page: page,
                    ...this.params
                }
            })
            .then((response) => {
                this.dates = response.data;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },
    },
    created(){
        this.getEntries();
    }

}
</script>

<style>

</style>